<script>
    import { getContext } from 'svelte';
    import Button from 'components/Button';

    export let onClose = () => {};

    const { close } = getContext('simple-modal');

    function handleClose() {
        onClose();
        close();
    }

</script>

<style lang="scss">
    @import 'src/sass/base';

    .modal-content {
        margin-bottom: em(30);
        padding: em(30) em(30) 0 em(30);

        &__title {
            margin-bottom: em(25);
            font-size: em(20);
            font-weight: $fw-bold;
            color: $color-primary;
            line-height: 1.25;
        }

        :global(p) {
            margin-bottom: em(25);
        }
    }

    .contacts {
        display: grid;
        justify-content: flex-start;
        grid-template-columns: 1fr 1fr;
        flex-flow: row wrap;
        align-items: center;
        margin-top: em(30);

        &__contact {
            width: 100%;
            margin: em(20) 0;
            font-size: em(14);
        }

        &__name {
            margin-bottom: em(10);
            color: $color-black;
        }

        &__number {
            font-weight: $fw-bold;
            color: $color-primary;
        }
    }

    .actions {
        padding: em(10) em(25);
        border-radius: 0 0 em(25) em(25);
        background: $color-gray-light;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: auto;
    }

</style>

<div class="modal">
    <div class="modal-content">
        <h2 class="modal-content__title">Contact us</h2>
        <div class="modal__desc">
            <p>Term Finance is a web-based lender operating across the Caribbean.</p>
            <div class="contacts">
                <div class="contacts__contact">
                    <div class="contacts__name">Call us in Trinidad & Tobago</div>
                    <div class="contacts__number">1-868-235-5650</div>
                </div>
                <div class="contacts__contact">
                    <div class="contacts__name">Call us in Barbados</div>
                    <div class="contacts__number">1-246-428-8376</div>
                </div>
                <div class="contacts__contact">
                    <div class="contacts__name">Call us in Guyana</div>
                    <div class="contacts__number">1-592-608-8376</div>
                </div>
                <div class="contacts__contact">
                    <div class="contacts__name">Call us in Jamaica</div>
                    <div class="contacts__number">1-876-891-8376</div>
                </div>
                <div class="contacts__contact">
                    <div class="contacts__name">Call us in St. Lucia</div>
                    <div class="contacts__number">1-758-484-8376</div>
                </div>
                <div class="contacts__contact">
                    <div class="contacts__name">Email us</div>
                    <a href="mailto:info@mytermfinance.com" class="contacts__number">info@mytermfinance.com</a>
                </div>
            </div>
        </div>
    </div>
    <div class="actions">
        <Button primary small onClick={handleClose}>OK</Button>
    </div>
</div>
