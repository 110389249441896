<script>
    import { onMount, getContext } from 'svelte';
    import Lottie from 'components/Lottie';

    export let title;
    export let message;
    export let promise = null;
    export let onSuccess = () => {};
    export let onError = () => {};
    export let onClose = () => {};

    const { close } = getContext('simple-modal');

    onMount(() => {
        setTimeout(() => {
            if (promise) {
                promise.then(
                    (data) => {
                        handleClose(onSuccess, data);
                    },
                    (error) => {
                        handleClose(onError, error);
                    },
                );
            } else {
                handleClose();
            }
        }, 1500);
    });

    function handleClose(callback, result) {
        close();
        callback(result);
        onClose();
    }

</script>

<style lang="scss">
    @import 'src/sass/base';

    .modal-content {
        margin-bottom: em(60);
        padding: em(45) em(45) 0 em(45);
        border-radius: 50px;
        text-align: center;

        &__loader {
            margin: 0 auto;
            margin-bottom: em(25);
            width: 100px;
            height: 100px;
        }

        &__title {
            margin-bottom: em(25);
            font-size: em(20);
            font-weight: $fw-bold;
            color: $color-primary;
            line-height: 1.25;
        }

        &__desc {
            font-size: em(16);
            color: $color-gray-text;
            line-height: 1.25;
        }

        :global(p) {
            margin-bottom: em(25);
        }
    }

</style>

<div class="modal">
    <div class="modal-content">
        <div class="modal-content__loader">
            <Lottie jsonPath="lottie/loading-circle.json" />
        </div>
        <h2 class="modal-content__title">{title}</h2>
        <p class="modal-content__desc">
            {@html message}
        </p>
    </div>
    <!-- <div class="actions">
        <Button primary onClick={handleClose}>{confirmLabel}</Button>
    </div> -->
</div>
