<script>
    import { onMount, onDestroy, getContext } from 'svelte';

    import LOANS_CONFIG from 'configs/loans';

    import authStore from 'stores/auth';
    import loansStore from 'stores/loans';

    import AppLayout from 'components/AppLayout';
    import Loader from 'components/Loader';
    import LoanCard from 'components/LoanCard';
    import LoanDetails from 'components/LoanDetails';
    import Button from 'components/Button';
    import NewLoanOverlay from 'components/NewLoanOverlay';
    import LoanAgreementOverlay from 'components/LoanAgreementOverlay';
    import { AlertModal, alertModalOptions } from 'components/AlertModal';

    const { open } = getContext('simple-modal');

    let isLoading = true;
    let selectedFilter = LOANS_CONFIG.LOAN_FILTERS.ACTIVE;
    let selectedLoans = [];
    let selectedLoan = null;
    let newLoanOverlay = false;
    let loanAgreementOverlay = false;

    onMount(() => {
        setTimeout(loadCustomerLoans, 100);
    });

    onDestroy(() => {});

    function loadCustomerLoans(force = false) {
        isLoading = true;

        loansStore.fetchLoans($authStore.customerId, force).finally(() => {
            isLoading = false;
            setFilter(LOANS_CONFIG.LOAN_FILTERS.ACTIVE);
        });
    }

    function setFilter(filter) {
        selectedFilter = filter;

        switch (selectedFilter.key) {
            case LOANS_CONFIG.LOAN_FILTERS.ACTIVE.key:
                selectedLoans = $loansStore.loans.active;
                break;
            case LOANS_CONFIG.LOAN_FILTERS.PAID.key:
                selectedLoans = $loansStore.loans.paid;
                break;
            case LOANS_CONFIG.LOAN_FILTERS.CANCELLED.key:
                selectedLoans = $loansStore.loans.cancelled;
                break;
            default:
                selectedLoans = [];
                break;
        }

        selectLoan(selectedLoans[0]);
    }

    function getNumLoansByFilter(filter) {
        switch (filter.key) {
            case LOANS_CONFIG.LOAN_FILTERS.ACTIVE.key:
                return $loansStore.loans.active.length;
            case LOANS_CONFIG.LOAN_FILTERS.PAID.key:
                return $loansStore.loans.paid.length;
            case LOANS_CONFIG.LOAN_FILTERS.CANCELLED.key:
                return $loansStore.loans.cancelled.length;
            default:
                return 0;
        }
    }

    function selectLoan(loan) {
        selectedLoan = loan;
    }

    function handleClickNewLoan() {
        let errorMsg;
        if ($loansStore.loans.active.length > 0) {
            let pendingLoans = $loansStore.loans.active.filter((loan) => {
                return loan.status == LOANS_CONFIG.LOAN_STATUSES.PENDING;
            });

            if (pendingLoans.length > 0) {
                errorMsg = 'You cannot apply a for new loan or top-up with existing Pending loans.';
            }
        }

        if (errorMsg) {
            open(
                AlertModal,
                {
                    title: "Sorry, you can't do that right now",
                    message: errorMsg,
                },
                alertModalOptions,
            );
        } else {
            newLoanOverlay = true;
        }
    }

</script>

<style lang="scss" type="text/scss">
    @import 'src/sass/base';

    .root {
        min-height: 100vh;
        background: rgb(230, 228, 254);
        background: linear-gradient(83deg, rgba(230, 228, 254, 1) 0%, rgba(203, 197, 255, 1) 100%);
    }

    .loans {
        overflow: hidden;
        display: flex;
        flex-flow: column nowrap;
        flex: 1;
    }

    .filters {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: center;
        flex-direction: column-reverse;
        width: 100%;
        padding: 0;
        background: darken($color-white, 1%);
        border-bottom: 1px solid darken($color-white, 10%);
        animation-duration: 0.25s;

        &__list {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
        }

        &__actions {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: em(20) em(10);
            border-bottom: 2px solid $color-secondary;
            column-gap: em(10);
        }

        @include for-tablet-landscape-up {
            flex-flow: row nowrap;
            flex-direction: row;

            &__list {
                width: 65%;
            }

            &__actions {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 35%;
                padding: 0;
                padding-right: em(25);
                border: none;
                column-gap: em(10);
            }
        }
    }

    .filter {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        width: 34%;
        margin: 0;
        padding: em(10) 0;
        color: $color-black;
        cursor: pointer;
        border-right: 1px solid darken($color-white, 10%);
        border-bottom: 2px solid transparent;

        &__label {
            width: 100%;
            margin-bottom: em(10);
            text-align: center;
            font-size: em(14);
        }

        &__count {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            background: $color-secondary;
            border-radius: 50%;
            font-size: em(11);
            font-weight: $fw-bold;
            color: $color-primary;
            border: 1px solid rgba($color-primary, 1);
        }

        &:hover {
            background: $color-secondary;
            animation: pulse;
            animation-duration: 0.25s;
        }

        &--active {
            background: $color-secondary;
            color: $color-primary !important;
            font-weight: $fw-medium !important;
            border-bottom: 2px solid $color-primary;

            &:hover {
                background: $color-secondary;
            }
        }

        @include for-tablet-landscape-up {
            flex-flow: row nowrap;
            justify-content: space-between;
            padding: em(20) em(20);

            &__label {
                width: auto;
                margin-bottom: 0;
                font-size: em(16);
            }

            &__count {
                font-size: em(14);
            }
        }
    }

    .loans-wrapper {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        padding: 0;

        @include for-tablet-landscape-up {
            flex-flow: row nowrap;
            padding: 0 em(25);
        }
    }

    .loans-messages {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -100px;
        flex: 1;
        align-self: stretch;
    }

    .loans-list {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 100%;
        max-height: 300px;
        margin-right: 0;
        padding: em(10) em(5);
        overflow-x: auto;

        &--no-scroll {
            overflow-x: hidden;
        }

        @include for-tablet-landscape-up {
            display: block;
            width: 40%;
            max-height: none;
            margin-right: em(5);
            padding: em(10) 0;
            overflow-x: visible !important;
        }
    }

    .loan-card {
        width: 100%;
        margin: 0 em(2) em(10) em(2);
        flex-shrink: 0;

        &--no-scroll {
            margin-right: 0;
            margin-left: 0;
        }

        @include for-tablet-landscape-up {
            margin: 0 0 em(10) 0;
        }
    }

</style>

<NewLoanOverlay
    trigger={newLoanOverlay}
    onClose={() => (newLoanOverlay = false)}
    onSubmit={() => {
        loadCustomerLoans(true);
    }} />

{#if selectedLoan}
    <LoanAgreementOverlay
        trigger={loanAgreementOverlay}
        loan={selectedLoan ? selectedLoan : null}
        onSubmit={() => {
            loadCustomerLoans(true);
        }}
        onClose={() => {
            loanAgreementOverlay = false;
        }} />
{/if}

<div class="root">
    <AppLayout title="Loans" subtitle="View, modify and action on your existing and past loans">
        <div slot="page">
            <div class="loans">
                {#if !isLoading}
                    <div class="filters animate__animated animate__fadeInDown">
                        <div class="filters__list">
                            {#each Object.keys(LOANS_CONFIG.LOAN_FILTERS) as filterKey, i}
                                <div
                                    class="filter"
                                    on:click={() => setFilter(LOANS_CONFIG.LOAN_FILTERS[filterKey])}
                                    class:filter--active={selectedFilter.key == LOANS_CONFIG.LOAN_FILTERS[filterKey].key}>
                                    <div class="filter__label">{LOANS_CONFIG.LOAN_FILTERS[filterKey].label}</div>
                                    <div class="filter__count">
                                        {getNumLoansByFilter(LOANS_CONFIG.LOAN_FILTERS[filterKey])}
                                    </div>
                                </div>
                            {/each}
                        </div>
                        <div class="filters__actions">
                            <Button
                                small
                                primary
                                onClick={() => {
                                    window.open('https://www.fygaro.com/en/pb/9b60fb2e-512c-49e8-b125-52a3a2fbdb63/', '_blank');
                                }}>
                                Make a Payment
                            </Button>
                            <Button small primary onClick={() => handleClickNewLoan()}>Apply for Loan / Top-up</Button>
                        </div>
                    </div>
                {/if}
                <div class="loans-wrapper">
                    {#if isLoading}
                        <div class="loans-messages">
                            <Loader title="Loading Loans ..." subtitle="This might take a few seconds ..." />
                        </div>
                    {:else if selectedLoans.length > 0}
                        <div
                            class="loans-list animate__animated animate__fadeInLeft"
                            class:loans-list--no-scroll={selectedLoans.length <= 1}>
                            {#each selectedLoans as loan, i (loan.id)}
                                <div
                                    class="loan-card animate__animated animate__fadeInLeft"
                                    class:loan-card--no-scroll={selectedLoans.length <= 1}
                                    style={`animation-delay: ${i * 150}ms`}>
                                    <LoanCard
                                        {loan}
                                        isSelected={loan.id == selectedLoan.id}
                                        onClick={() => selectLoan(loan)} />
                                </div>
                            {/each}
                        </div>
                        <LoanDetails
                            loan={selectedLoan}
                            on:signature={(e) => {
                                loanAgreementOverlay = true;
                            }} />
                    {:else}
                        <div class="loans-messages">
                            <Loader
                                animationPath="lottie/alert-list.json"
                                animationLoop={false}
                                title={`We can't find any ${selectedFilter.label} loans.`}
                                subtitle="Try selecting another filter from the list above." />
                        </div>
                    {/if}
                </div>
            </div>
        </div>
    </AppLayout>
</div>
