<script>
    import { onMount, onDestroy } from 'svelte';

    import REGISTER_CONFIG from 'configs/register';

    import customerStore from 'stores/customer';
    import authStore from 'stores/auth';

    import AppLayout from 'components/AppLayout';
    import Container from 'components/Container';

    let customer = $customerStore;

    onMount(() => {
        setTimeout(() => {
            customerStore.fetch($authStore.customerId).then((store) => {
                customer = store;
            });
        }, 100);
    });

    onDestroy(() => {});

    function getCustomField(id) {
        let field = customer.customFields.find((field) => {
            return field.Id == id;
        });

        return field.Value;
    }

</script>

<style lang="scss" type="text/scss">
    @import 'src/sass/base';

    .root {
        min-height: 100vh;
        background: rgb(230, 228, 254);
        background: linear-gradient(83deg, rgba(230, 228, 254, 1) 0%, rgba(203, 197, 255, 1) 100%);
    }

    .profile {
        overflow: hidden;
        margin: em(25) 0;
    }

    .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__avatar {
            margin-right: em(20);
            height: 75px;
        }

        &__details {
            width: 100%;
            padding-bottom: em(15);
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }

        &__name {
            font-size: em(24);
            font-weight: $fw-light;
            color: $color-black;
        }

        &__store {
            margin-top: em(8);
            font-size: em(16);
            font-weight: $fw-regular;
            color: $color-gray-text;
            font-family: $ff-primary;
        }

        @include for-tablet-portrait-up {
            height: 100px;

            &__name {
                font-size: em(36);
            }
        }
    }

    .section {
        margin: em(50) 0;

        &__title {
            font-size: em(18);
            color: $color-primary;
            font-weight: $fw-medium;
        }

        &__content {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;
            margin-top: em(20);
            padding: em(15) em(15);
            background: #fbf8f8;
            border-radius: em(5);
        }

        @include for-tablet-portrait-up {
            &__content {
                padding: em(25) em(50);
            }
        }
    }

    .detail {
        flex-basis: 50%;
        margin: em(25) 0;
        text-align: left;

        &__label {
            margin-bottom: em(5);
            font-size: em(14);
            color: $color-gray-text;
        }

        &__value {
            font-size: em(16);
            color: $color-black;
        }

        @include for-tablet-portrait-up {
            flex-basis: 33.3%;
            margin: em(50) 0;
            font-family: $ff-primary;

            &__label {
                font-size: em(16);
            }

            &__value {
                font-size: em(16);
                font-weight: $fw-medium;
            }
        }
    }

</style>

<div class="root">
    <AppLayout title="Profile" subtitle="View and edit your profile">
        <div slot="page">
            <div class="profile">
                <Container>
                    <div class="title">
                        <img
                            alt="User Avatar"
                            src={`/images/avatar_${$customerStore.gender}.png`}
                            class="title__avatar animate__animated animate__pulse" />
                        <div class="title__details">
                            <div class="title__name animate__animated animate__fadeIn">{customer.fullName}</div>
                            <div class="title__store">
                                {customer.store.isSME ? customer.employer.name : customer.store.name}
                            </div>
                        </div>
                    </div>
                    <section class="section animate__animated animate__fadeIn">
                        <div class="section__title">My Details</div>
                        <div class="section__content">
                            <div class="detail">
                                <div>
                                    <div class="detail__label">Full Name</div>
                                    <div class="detail__value">{customer.fullName}</div>
                                </div>
                            </div>
                            <div class="detail">
                                <div>
                                    <div class="detail__label">Gender</div>
                                    <div class="detail__value">{customer.gender}</div>
                                </div>
                            </div>
                            <div class="detail">
                                <div>
                                    <div class="detail__label">Birthdate</div>
                                    <div class="detail__value">{customer.birthDate}</div>
                                </div>
                            </div>
                            <div class="detail">
                                <div>
                                    <div class="detail__label">Home Phone</div>
                                    <div class="detail__value">{customer.phoneNumber.home}</div>
                                </div>
                            </div>
                            <div class="detail">
                                <div>
                                    <div class="detail__label">Cell Phone</div>
                                    <div class="detail__value">{customer.phoneNumber.mobile}</div>
                                </div>
                            </div>
                            <div class="detail">
                                <div>
                                    <div class="detail__label">Email Address</div>
                                    <div class="detail__value">{customer.emailAddress}</div>
                                </div>
                            </div>
                            <div class="detail">
                                <div>
                                    <div class="detail__label">Address</div>
                                    <div class="detail__value">
                                        <div>{customer.address.street}</div>
                                        <div>{customer.address.city}</div>
                                        <div>{customer.address.state}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {#if !customer.store.isSME}
                        <section class="section animate__animated animate__fadeIn">
                            <div class="section__title">My Employer</div>
                            <div class="section__content">
                                <div class="detail">
                                    <div>
                                        <div class="detail__label">Employer</div>
                                        <div class="detail__value">{customer.employer.name}</div>
                                    </div>
                                </div>
                                <div class="detail">
                                    <div>
                                        <div class="detail__label">Occupation</div>
                                        <div class="detail__value">{customer.employer.occupation}</div>
                                    </div>
                                </div>
                                <div class="detail">
                                    <div>
                                        <div class="detail__label">Date of Hire</div>
                                        <div class="detail__value">{customer.employer.dateHired}</div>
                                    </div>
                                </div>
                                <div class="detail">
                                    <div>
                                        <div class="detail__label">Address</div>
                                        <div class="detail__value">
                                            <div>{customer.employer.address.street}</div>
                                            <div>{customer.employer.address.city}</div>
                                            <div>{customer.employer.address.state}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    {:else}
                        <section class="section animate__animated animate__fadeInRight" style="animation-delay: 0.75s">
                            <div class="section__title">My Business</div>
                            <div class="section__content">
                                <div class="detail">
                                    <div>
                                        <div class="detail__label">Name</div>
                                        <div class="detail__value">{customer.employer.name}</div>
                                    </div>
                                </div>
                                <div class="detail">
                                    <div>
                                        <div class="detail__label">Operations Start Date</div>
                                        <div class="detail__value">
                                            {getCustomField(REGISTER_CONFIG.CUSTOM_FIELDS.OPERATIONS_START_DATE)}
                                        </div>
                                    </div>
                                </div>
                                <div class="detail">
                                    <div>
                                        <div class="detail__label">Full-time Employees</div>
                                        <div class="detail__value">
                                            {getCustomField(REGISTER_CONFIG.CUSTOM_FIELDS.FULL_TIME_EMPLOYEES)}
                                        </div>
                                    </div>
                                </div>
                                <div class="detail">
                                    <div>
                                        <div class="detail__label">Part-time Employees</div>
                                        {getCustomField(REGISTER_CONFIG.CUSTOM_FIELDS.PART_TIME_EMPLOYEES)}
                                    </div>
                                </div>
                                <div class="detail">
                                    <div>
                                        <div class="detail__label">Address</div>
                                        <div class="detail__value">
                                            <div>{customer.employer.address.street}</div>
                                            <div>{customer.employer.address.city}</div>
                                            <div>{customer.employer.address.state}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    {/if}
                </Container>
            </div>
        </div>
    </AppLayout>
</div>
