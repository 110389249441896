<script>
    import LOANS_CONFIG from 'configs/loans';

    export let status;
    export let shake = false;
    export let isCustom = false;

</script>

<style lang="scss">
    @import 'src/sass/base';

    .loan-card-status {
        font-size: em(11);
        padding: em(8) em(10);
        font-weight: $fw-regular;
        text-transform: uppercase;
        letter-spacing: 1.15px;
        background: yellow;
        border-radius: em(5);

        &--active {
            background: $color-green;
            color: $color-white;
        }

        &--pending {
            background: $color-orange;
            color: $color-white;
        }

        &--denied {
            background: $color-red;
            color: $color-white;
        }

        &--paid {
            background: darken($color-green, 5%);
            color: $color-white;
        }
    }

    .shake {
        animation: pulse;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }

</style>

{#if !isCustom}
    <div
        class="loan-card-status animate__animated"
        class:shake
        class:loan-card-status--active={status == LOANS_CONFIG.LOAN_STATUSES.ACTIVE}
        class:loan-card-status--pending={status == LOANS_CONFIG.LOAN_STATUSES.PENDING}
        class:loan-card-status--paid={status == LOANS_CONFIG.LOAN_STATUSES.PAID}
        class:loan-card-status--denied={status == LOANS_CONFIG.LOAN_STATUSES.WITHDRAWN || status == LOANS_CONFIG.LOAN_STATUSES.REVERSED || status == LOANS_CONFIG.LOAN_STATUSES.DENIED || status == LOANS_CONFIG.LOAN_STATUSES.PAST_DUE}>
        {status}
    </div>
{:else}
    <div class="loan-card-status animate__animated" class:shake>
        <slot />
    </div>
{/if}
