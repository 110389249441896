export default {
    LOAN_STATUSES: {
        ACTIVE: 'Active',
        PENDING: 'Pending',
        PAID: 'Paid',
        PAST_DUE: 'PastDue',
        WITHDRAWN: 'Withdrawn',
        WRITTEN_OFF: 'WrittenOff',
        REVERSED: 'Reversed',
        DENIED: 'Denied',
    },

    LOAN_FILTERS: {
        ACTIVE: {
            key: 1,
            label: 'Active',
        },
        PAID: {
            key: 2,
            label: 'Paid',
        },
        CANCELLED: {
            key: 3,
            label: 'Cancelled',
        },
    },
};
