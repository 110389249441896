<script>
    import { onMount } from 'svelte';

    export let animationData = null;
    export let jsonPath = null;
    export let loop = true;
    export let autoplay = true;

    let params = {};
    let el;

    // Generate a random ID so that we can reference
    // it in Lottie's 'loadAnimation' method
    let id = '_' + Math.random().toString(36).substr(2, 9);

    $: {
        if (el) {
            el.innerHTML = '';
        }

        params.path = jsonPath;
        params.loop = loop;
        window.lottie.loadAnimation(params);
    }

    onMount(() => {
        params = {
            container: document.querySelector(`#${id}`),
            renderer: 'svg',
            loop: loop,
            autoplay: autoplay,
        };

        // Either pass the 'animationData' or JSON path
        // to Lottie
        if (animationData) {
            params.animationData = animationData;
        } else {
            params.path = jsonPath;
        }
    });

</script>

<div {id} bind:this={el} />
