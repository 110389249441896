<script>
    import { getContext } from 'svelte';
    import { link, push, location } from 'svelte-spa-router';
    import active from 'svelte-spa-router/active';

    import authStore from 'stores/auth';
    import customerStore from 'stores/customer';
    import docsStore from 'stores/docs';
    import loansStore from 'stores/loans';
    import registerStore from 'stores/register';

    import Lottie from 'components/Lottie';
    import { SupportModal, supportModalOptions } from 'components/SupportModal';

    const { open } = getContext('simple-modal');

    export let title;
    export let subtitle;

    function openSupportModal() {
        open(SupportModal, {}, supportModalOptions);
    }

    function logout() {
        authStore.reset();
        customerStore.reset();
        docsStore.reset();
        loansStore.reset();
        registerStore.reset();

        push('/');
    }

</script>

<style lang="scss">
    @import 'src/sass/base';

    .root {
        min-height: 100vh;
        margin: 0;
        padding: 0;
        background: $color-secondary;

        @include for-desktop-up {
            padding: 0 0 0;
        }
    }

    .shell {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 auto;
        width: 100%;
        min-height: 100vh;
        background: #f4f2f2;
        box-shadow: none;
        overflow-x: hidden;

        @include for-desktop-up {
            flex-flow: row nowrap;
            width: 100%;
            box-shadow: rgb(0 0 0 / 10%) 0 0 10px 2px;
        }
    }

    .nav {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        position: relative;
        z-index: 200;
        width: 100%;
        max-width: 100%;
        min-height: none;
        padding: em(5);
        background: $color-primary;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.13) 0px 2px 2px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &__logo {
            padding: 0;
            margin: 0;
            animation: pulse;
            animation-duration: 2s;
            animation-iteration-count: infinite;

            img {
                height: 50px;
            }
        }

        &__links {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @include for-desktop-up {
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            width: 30%;
            max-width: 150px;
            min-height: 100vh;
            z-index: 1;
            padding: 0;

            &__logo {
                padding: em(25) em(10);

                img {
                    height: 50px;
                }
            }

            &__links {
                width: 100%;
                flex-flow: column nowrap;
            }
        }
    }

    .nav-link {
        display: flex;
        flex: row nowrap;
        justify-content: space-evenly;
        align-items: center;
        width: auto;
        padding: 0;
        margin: 0;
        font-weight: $fw-regular;
        color: $color-white;
        cursor: pointer;
        transition: none;

        &--logout {
            opacity: 1 !important;
        }

        &__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            padding: em(3);
            background: $color-secondary;
            border-radius: 50%;
            margin-left: em(10);
        }

        &__label {
            display: none;
            font-size: em(16);
        }

        @include for-desktop-up {
            width: 100%;
            justify-content: flex-start;
            padding: em(18) em(10);
            transition: 0.25s ease-in all;

            &--logout {
                display: none;
            }

            &:hover {
                background: lighten($color-primary, 10%);
                color: $color-white;
            }

            &__icon {
                margin-left: 0;
                margin-right: em(20);
            }

            &__label {
                display: block;
            }
        }
    }

    .content {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        min-height: 100vh;
    }

    .header {
        position: relative;
        z-index: 100;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        background: $color-white;
        border-bottom: 1px solid rgba($color-black, 0.05);

        @include for-desktop-up {
            flex-flow: row nowrap;
            background: $color-white;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
            border-bottom: none;
        }
    }

    .header-copy {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: em(20) em(10);

        &__title {
            font-weight: $fw-bold;
            font-size: em(24);
        }

        &__desc {
            display: none;
            margin-top: em(6);
            font-weight: $fw-regular;
            font-size: em(14);
            color: $color-gray-text;
            font-family: $ff-primary;
        }

        @include for-desktop-up {
            width: 65%;
            padding: em(15) em(25);

            &__title {
                font-size: em(24);
            }

            &__desc {
                font-size: em(16);
            }
        }
    }

    .header-nav {
        display: none;
        justify-content: flex-end;
        column-gap: em(30);
        align-items: center;
        width: 25%;
        padding: em(10) em(25);

        @include for-desktop-up {
            width: 35%;
            display: flex;
        }
    }

    .header-nav-user {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 em(30);
        border-right: 1px solid $color-gray-text;

        &__loader {
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }

        &__icon {
            margin-right: em(10);
        }

        &__name {
            display: none;
            font-size: em(14);
            font-weight: $fw-medium;
            color: $color-primary;
        }

        &__desc {
            display: none;
            margin-top: em(5);
            font-size: em(14);
            color: $color-gray-text;
            font-family: $ff-primary;
        }

        @include for-desktop-up {
            &__name,
            &__desc {
                display: block;
            }

            &__icon {
                height: 50px;
            }
        }
    }

    .header-nav-btns {
        display: none;

        &__btn {
            cursor: pointer;
            width: em(40);
            padding: em(5);
            background: $color-secondary;
            border-radius: 50%;
            transition: 0.1s all ease-in;

            &:hover {
                background: darken($color-secondary, 5%);
            }
        }

        @include for-desktop-up {
            display: block;
        }
    }

    .page {
        display: flex;
        flex-flow: column nowrap;
        flex: 1;
        position: relative;
        z-index: 1;
    }

    :global(.page > div:first-of-type) {
        display: flex;
        flex-flow: column nowrap;
        flex-grow: 1;
    }

    :global(.nav-link.active) {
        background: none !important;
        color: $color-white !important;
        font-weight: $fw-bold !important;
        opacity: 1 !important;

        @include for-desktop-up {
            background: lighten($color-primary, 10%) !important;
        }
    }

</style>

<div class="root">
    <div class="shell">
        <nav class="nav">
            <div class="nav__logo"><img src="/images/tf_loans_logo_white@2x.png" alt="logo" /></div>
            <div class="nav__links">
                <div class="nav-link" use:active={'/loans'} on:keydown on:click={() => push('/loans')}>
                    <div class="nav-link__icon">
                        <Lottie
                            jsonPath="lottie/slide_1_icon.json"
                            loop={$location == '/loans'}
                            autoplay={$location == '/loans'} />
                    </div>
                    <span class="nav-link__label">Loans</span>
                </div>
                <div class="nav-link" use:active={'/docs'} on:keydown on:click={() => push('/docs')}>
                    <div class="nav-link__icon">
                        <Lottie
                            jsonPath="lottie/nav_documents_icon.json"
                            loop={$location == '/docs'}
                            autoplay={$location == '/docs'} />
                    </div><span class="nav-link__label">Docs</span>
                </div>
                <div class="nav-link" use:active={'/profile'} on:keydown on:click={() => push('/profile')}>
                    <div class="nav-link__icon">
                        <Lottie
                            jsonPath="lottie/nav_profile_icon.json"
                            loop={$location == '/profile'}
                            autoplay={$location == '/profile'} />
                    </div><span class="nav-link__label">Profile</span>
                </div>
                <div class="nav-link" on:keydown on:click={() => openSupportModal()}>
                    <div class="nav-link__icon">
                        <Lottie jsonPath="lottie/nav_support_icon.json" loop={false} />
                    </div><span class="nav-link__label">Support</span>
                </div>
                <div class="nav-link nav-link--logout" on:keydown on:click={() => logout()}>
                    <div class="nav-link__icon">
                        <Lottie jsonPath="lottie/nav_logout_icon.json" loop={false} />
                    </div><span class="nav-link__label">Logout</span>
                </div>
            </div>
        </nav>
        <div class="content">
            <div class="header">
                <div class="header-copy">
                    <div class="header-copy__title">{title}</div>
                    <div class="header-copy__desc">{subtitle}</div>
                </div>
                <div class="header-nav">
                    <div class="header-nav-user">
                        {#if $customerStore.gender}
                            <div class="header-nav-user__loader">
                                <Lottie jsonPath="lottie/nav_profile_icon.json" loop={false} />
                            </div>
                        {:else}
                            <div class="header-nav-user__loader">
                                <Lottie jsonPath="/lottie/loading-circle.json" />
                            </div>
                        {/if}
                        <div class="header-nav-user__avatar">
                            {#if $customerStore.fullName}
                                <div class="header-nav-user__name">
                                    <a href="/profile" use:link>Hey, {$customerStore.fullName}!</a>
                                </div>
                                <div class="header-nav-user__desc">
                                    {$customerStore.store.isSME ? $customerStore.employer.name : $customerStore.store.name}
                                </div>
                            {/if}
                        </div>
                    </div>
                    <div class="header-nav-btns">
                        <div class="header-nav-btns__btn" on:click={() => logout()}>
                            <Lottie jsonPath="lottie/nav_logout_icon.json" loop={false} />
                        </div>
                    </div>
                </div>
            </div>
            <div class="page">
                <slot name="page" />
            </div>
        </div>
    </div>
</div>
