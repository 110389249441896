export default {
    LOAN_TYPES: {
        PERSONAL: 1,
        BUSINESS: 2,
        CAR: 3,
    },

    CAR_LOAN_TYPES: {
        COLLATERAL: 10,
        PURCHASE: 20,
    },

    STORES: {
        TEST: 1,
        '1_GY': 404,
        '1_TT': 2,
        '1_BB': 403,
        '1_JM': 405,
        '1_LC': 406,
        '1_AG': 932,
        '2_TT': 437,
        '2_BB': 586,
    },

    COUNTRIES: {
        TT: {
            key: 'TT',
            name: 'Trindad & Tobago',
            locale: 'tt',
            currency: 'TTD',
            areaCode: '868',
        },
        BB: {
            key: 'BB',
            name: 'Barbados',
            locale: 'bb',
            currency: 'BBD',
            areaCode: '246',
        },
        JM: {
            key: 'JM',
            name: 'Jamaica',
            locale: 'jm',
            currency: 'JMD',
            areaCode: '876',
        },
        GY: {
            key: 'GY',
            name: 'Guyana',
            locale: 'gy',
            currency: 'GYD',
            areaCode: '592',
        },
        LC: {
            key: 'LC',
            name: 'St. Lucia',
            locale: 'lc',
            currency: 'XCD',
            areaCode: '758',
        },
        VC: {
            key: 'VC',
            name: 'St. Vincent',
            locale: 'vc',
            currency: 'XCD',
            areaCode: '1',
        },
        AG: {
            key: 'AG',
            name: 'Antigua',
            locale: 'ag',
            currency: 'XCD',
            areaCode: '1',
        },
    },
};
