<script>
    import LOANS_CONFIG from 'configs/loans';

    import { formatCurrency } from 'services/util';

    import Pill from 'components/Pill';
    import LoanCardStatus from 'components/LoanCard/LoanCardStatus';

    export let loan;
    export let isSelected = false;
    export let onClick = () => {};

    function getLoanProgress() {
        return (loan.amountPaid / loan.amount) * 100;
    }

</script>

<style lang="scss">
    @import 'src/sass/base';

    .loan-card {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: em(15) em(20) em(30) em(20);
        background: $color-white;
        border: 2px solid transparent;
        border-radius: em(8);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        cursor: pointer;
        opacity: 0.65;

        &:hover {
            opacity: 1;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
            background: $color-secondary;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--active {
            background: $color-secondary;
            border-color: $color-primary;
            box-shadow: 0px 0px 10px -3px $color-primary;
            animation: pulse;
            animation-duration: 0.25s;
            opacity: 1;

            &:hover {
                box-shadow: 0px 0px 10px -3px $color-primary;
            }
        }
    }

    .loan-card-details {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-bottom: em(35);

        &__amount {
            margin-bottom: em(10);
            font-size: em(24);
            font-weight: $fw-medium;
            color: $color-primary;
        }

        &__status {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: em(5);
        }

        @include for-tablet-landscape-up {
            flex-flow: row nowrap;
            align-items: center;

            &__amount {
                margin-bottom: 0;
                font-size: em(24);
            }
        }
    }

    .loan-progress {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 7px;
        margin-bottom: em(25);
        background: darken($color-gray, 10%);
        border-radius: em(8);

        &__fill {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background: $color-green;
        }
    }

    .loan-card-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .loan-card-item {
        font-family: $ff-primary;

        &__title {
            margin-bottom: em(5);
            color: $color-gray-text;
            font-size: em(14);
        }

        &__value {
            color: $color-black;
            font-size: em(14);
        }

        @include for-tablet-landscape-up {
            &__title,
            &__value {
                font-size: em(16);
            }
        }
    }

</style>

<div class="loan-card" class:loan-card--active={isSelected} on:click={onClick}>
    {#if loan.status == LOANS_CONFIG.LOAN_STATUSES.ACTIVE || loan.status == LOANS_CONFIG.LOAN_STATUSES.PENDING}
        <div class="loan-progress">
            <div class="loan-progress__fill" style={`width: ${getLoanProgress()}%`} />
        </div>
    {/if}
    <div class="loan-card-details">
        <div class="loan-card-details__amount">{formatCurrency(loan.amount, 'TT', 'USD')}</div>
        <div class="loan-card-details__status">
            <LoanCardStatus status={loan.status} />
            {#if !loan.customerSignature && (loan.status == LOANS_CONFIG.LOAN_STATUSES.ACTIVE || loan.status == LOANS_CONFIG.LOAN_STATUSES.PENDING)}
                <Pill urgent>Needs Signature</Pill>
            {/if}
        </div>
    </div>
    <div class="loan-card-items">
        <div class="loan-card-item">
            <div class="loan-card-item__title">Paid</div>
            <div class="loan-card-item__value">{formatCurrency(loan.amountPaid, 'TT', 'USD')}</div>
        </div>
        <div class="loan-card-item">
            <div class="loan-card-item__title">Outstanding</div>
            <div class="loan-card-item__value">{formatCurrency(loan.amountDue, 'TT', 'USD')}</div>
        </div>
        <div class="loan-card-item">
            <div class="loan-card-item__title">Next Payment Due</div>
            <div class="loan-card-item__value">{loan.dueDate ? loan.dueDate : 'N/A'}</div>
        </div>
    </div>
</div>
