<script>
</script>

<style lang="scss">
    @import 'src/sass/base';

    .container {
        margin: 0 auto;
        padding: 0 em(25);

        @include for-tablet-portrait-up {
            margin: 0 auto;
            padding: 0 em(25);
        }

        @include for-desktop-up {
            margin: 0 auto;
            padding: 0 em(25);
        }
    }

</style>

<div class="container">
    <slot />
</div>
