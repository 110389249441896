<script>
    import { getContext } from 'svelte';
    import { getApp } from 'firebase/app';
    import { getFirestore, collection, addDoc } from 'firebase/firestore';
    import { serverTimestamp } from 'firebase/firestore';

    import MaskInput from 'svelte-input-mask/MaskInput.svelte';
    import Button from 'components/Button';
    import Lottie from 'components/Lottie';
    import Loader from 'components/Loader';

    export let onClose = () => {};
    export let product;

    let isLoading = false,
        isComplete = false;
    let error;

    let name, emailAddress, phoneNumber, company;

    const { close } = getContext('simple-modal');

    async function handleSubmit() {
        const db = getFirestore(getApp());

        error = null;
        if (!product || !name || !company || !emailAddress || !phoneNumber) {
            error = 'Please input all fields before submitting.';
            return;
        }

        isLoading = true;

        try {
            await addDoc(collection(db, 'ProductWaitlist'), {
                product,
                name,
                company,
                emailAddress,
                phoneNumber,
                dateCreated: serverTimestamp(),
            });

            setTimeout(() => {
                isComplete = true;
                isLoading = false;
            }, 2000);
        } catch (e) {
            console.error('User not added to Waitlist: ', e);
            error = 'Oops! Something went wrong. Please try again.';
            isLoading = false;
        }
    }

    function handleClose() {
        onClose();
        close();
    }

</script>

<style lang="scss">
    @import 'src/sass/base';

    .modal-content {
        margin-bottom: em(30);
        padding: em(30) em(30) 0 em(30);

        &__title {
            margin-bottom: em(5);
            font-size: em(28);
            font-weight: $fw-bold;
            color: $color-primary;
            line-height: 1.25;
            text-align: center;
        }

        &__subtitle {
            margin-bottom: em(30);
            font-size: em(20);
            color: $color-black;
            text-align: center;
            line-height: 1.1;
        }

        :global(p) {
            margin-bottom: em(25);
        }
    }

    .errors {
        margin: em(20) 0;
        color: $color-red;
        text-align: center;
    }

    .form {
        &__control {
            margin: em(20) 0;
        }

        &__input {
            padding: em(10);
        }
    }

    .actions {
        padding: em(20) em(25);
        border-radius: 0 0 em(25) em(25);
        background: $color-gray-light;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: em(5);
        width: auto;
    }

</style>

<div class="modal">
    <div class="modal-content">
        <h2 class="modal-content__title">{product}</h2>
        <div class="modal-content__subtitle">Sign up today to learn more about our {product} program.</div>
        {#if error}
            <div class="errors">{error}</div>
        {/if}
        <div class="modal__desc">
            {#if isLoading}
                <Loader title="Signing you up ..." subtitle="This might take a few seconds." />
            {:else if isComplete}
                <Loader
                    animationPath="lottie/register-checkmark.json"
                    animationLoop={false}
                    title="You're signed up!"
                    subtitle={product == 'Invest' ? 'Thank you for your interest in our Investment Product. This product is still in development. Our team will be in touch with you as soon as we go live.' : 'Thank you for your interest in our Financial Wellness program. Our team will be in touch with you as soon as our next session is scheduled.'} />
            {:else}
                <form class="form" name="waitlistForm">
                    <div class="form__control">
                        <label for="name" class="form__label">Full Name</label>
                        <input class="form__input" name="name" bind:value={name} />
                    </div>
                    <div class="form__control">
                        <label for="company" class="form__label">Employer/Business Name</label>
                        <input class="form__input" name="company" bind:value={company} />
                    </div>
                    <div class="form__control">
                        <label for="emailAddress" class="form__label">Email Address</label>
                        <input class="form__input" type="email" name="emailAddress" bind:value={emailAddress} />
                    </div>
                    <div class="form__control">
                        <label for="phoneNumber" class="form__label">Phone Number</label>
                        <input class="form__input" type="tel" name="phoneNumber" bind:value={phoneNumber} />
                    </div>
                </form>
            {/if}
        </div>
    </div>
    <div class="actions">
        <Button
            primary
            fullWidth
            disabled={isLoading}
            onClick={() => {
                if (isComplete) {
                    handleClose();
                } else {
                    handleSubmit();
                }
            }}>
            {isComplete ? 'Close' : 'Sign up'}
        </Button>
    </div>
</div>
