import SHARED_CONFIG from 'configs/shared';

export default {
    TYPES: {
        [SHARED_CONFIG.LOAN_TYPES.PERSONAL]: {
            inputs: {
                employment: {
                    label: 'How long have you been employed by your current company?',
                    options: [
                        {
                            label: '1 year or less',
                            value: 1,
                        },
                        {
                            label: '1-2 years',
                            value: 2,
                        },
                        {
                            label: '2-3 years',
                            value: 3,
                        },
                        {
                            label: '3 years or more',
                            value: 5,
                        },
                    ],
                },
                salary: {
                    label: 'What is your net monthly salary after all deductions?',
                    limits: {
                        [SHARED_CONFIG.COUNTRIES.TT.key]: {
                            minAmount: 1000,
                            maxAmount: 50000,
                            step: 100,
                        },
                        [SHARED_CONFIG.COUNTRIES.BB.key]: {
                            minAmount: 1000,
                            maxAmount: 15000,
                            step: 100,
                        },
                        [SHARED_CONFIG.COUNTRIES.GY.key]: {
                            minAmount: 30000,
                            maxAmount: 1500000,
                            step: 100,
                        },
                        [SHARED_CONFIG.COUNTRIES.JM.key]: {
                            minAmount: 20000,
                            maxAmount: 1000000,
                            step: 100,
                        },
                        [SHARED_CONFIG.COUNTRIES.LC.key]: {
                            minAmount: 1000,
                            maxAmount: 25000,
                            step: 100,
                        },
                        [SHARED_CONFIG.COUNTRIES.VC.key]: {
                            minAmount: 1000,
                            maxAmount: 25000,
                            step: 100,
                        },
                        [SHARED_CONFIG.COUNTRIES.AG.key]: {
                            minAmount: 1000,
                            maxAmount: 25000,
                            step: 100,
                        },
                    },
                },
                borrowing: {
                    label: 'What portion of your salary would you like to borrow?',
                    limits: {
                        [SHARED_CONFIG.COUNTRIES.TT.key]: {
                            step: 100,
                        },
                        [SHARED_CONFIG.COUNTRIES.BB.key]: {
                            step: 100,
                        },
                        [SHARED_CONFIG.COUNTRIES.GY.key]: {
                            step: 100,
                        },
                        [SHARED_CONFIG.COUNTRIES.JM.key]: {
                            step: 100,
                        },
                        [SHARED_CONFIG.COUNTRIES.LC.key]: {
                            step: 100,
                        },
                        [SHARED_CONFIG.COUNTRIES.VC.key]: {
                            step: 100,
                        },
                        [SHARED_CONFIG.COUNTRIES.AG.key]: {
                            step: 100,
                        },
                    },
                },
                numPayments: {
                    label: 'Over how many payments would you like to pay it back?',
                    options: {
                        default: [3, 6],
                        1: [3, 6, 9, 12],
                        2: [3, 6, 9, 12, 15, 18, 21, 24],
                        3: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
                        5: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48],
                    },
                },
            },
            labels: {
                title: 'We like to be upfront and transparent about your monthly payments and interest, so you don’t experience any surprises.',
                subtitle: 'Use our interactive loan calculator to specify your loan amount and payback period',
                borrowing: 'Amount you are borrowing',
                fees: 'Total Interest and Fees',
                paymentPlan: 'Indicative Repayment Plan',
                disclaimer:
                    ' Please note payments quoted in the repayment plan above would have to be made at the end of each month in which they fall due.',
            },
        },
        [SHARED_CONFIG.LOAN_TYPES.BUSINESS]: {
            inputs: {
                borrowing: {
                    label: 'How much does your business need to borrow?',
                    limits: {
                        [SHARED_CONFIG.COUNTRIES.TT.key]: {
                            minAmount: 10000,
                            maxAmount: 250000,
                            step: 5000,
                            defaultBorrowAmount: 50000,
                        },
                        [SHARED_CONFIG.COUNTRIES.BB.key]: {
                            minAmount: 5000,
                            maxAmount: 100000,
                            step: 2500,
                            defaultBorrowAmount: 15000,
                        },
                    },
                },
                numPayments: {
                    label: 'Over how many payments would your business like to pay it back?',
                    options: [3, 5, 12, 18, 24, 36, 48, 60],
                },
            },
            labels: {
                title: 'We like to be upfront and transparent about your monthly payments and interest, so you don’t experience any surprises.',
                subtitle: 'Use our interactive loan calculator to specify your loan amount and payback period',
                borrowing: 'Amount your business is borrowing',
                fees: 'Total Interest and Fees',
                paymentPlan: 'Indicative Repayment Plan',
                disclaimer:
                    ' Please note payments quoted in the repayment plan above would have to be made at the end of each month in which they fall due.',
            },
        },
        [SHARED_CONFIG.LOAN_TYPES.CAR]: {
            inputs: {
                loanType: {
                    label: 'What type of car loan are you interested in?',
                    options: [
                        {
                            label: 'Use my existing vehicle as collateral',
                            value: SHARED_CONFIG.CAR_LOAN_TYPES.COLLATERAL,
                        },
                        {
                            label: 'Buy a new or used vehicle',
                            value: SHARED_CONFIG.CAR_LOAN_TYPES.PURCHASE,
                        },
                    ],
                },
                car: {
                    [SHARED_CONFIG.CAR_LOAN_TYPES.COLLATERAL]: {
                        label: 'What is the estimated value of your existing vehicle?',
                        desc: 'You are elligible to borrow up to 70% of the total value of your existing vehicle.',
                        minAmount: 15000,
                        maxAmount: 500000,
                        step: 1000,
                        default: 50000,
                    },
                    [SHARED_CONFIG.CAR_LOAN_TYPES.PURCHASE]: {
                        label: 'What is the estimated cost of the vehicle you want to purchase?',
                        desc: 'You are elligible to borrow up to 80% of the total value of the vehicle.',
                        minAmount: 15000,
                        maxAmount: 500000,
                        step: 1000,
                        default: 50000,
                    },
                },
                downPayment: {
                    label: 'How much will put down as a down payment on the vehicle?',
                    desc: 'You will be required to put down a minimum of 20% of the total value of the vehicle.',
                    minAmount: 3000,
                    maxAmount: 450000,
                    step: 1000,
                    default: 10000,
                },
                borrowing: {
                    label: 'How much do you or your business need to borrow?',
                    limits: {
                        [SHARED_CONFIG.COUNTRIES.TT.key]: {
                            minAmount: 10500,
                            maxAmount: 35000,
                            step: 1000,
                            defaultBorrowAmount: 35000,
                        },
                    },
                },
                numPayments: {
                    label: 'Over how many payments would you like to pay it back?',
                    options: {
                        default: [12, 24, 36, 48, 60],
                        [SHARED_CONFIG.CAR_LOAN_TYPES.COLLATERAL]: [12, 24, 36, 48, 60],
                        [SHARED_CONFIG.CAR_LOAN_TYPES.PURCHASE]: [12, 24, 36, 48, 60, 72, 84],
                    },
                },
            },
            labels: {
                title: 'We like to be upfront and transparent about your monthly payments and interest, so you don’t experience any surprises.',
                subtitle: 'Use our interactive loan calculator to specify your loan amount and payback period',
                borrowing: 'Amount you are borrowing',
                fees: 'Total Interest and Fees',
                paymentPlan: 'Indicative Repayment Plan',
                disclaimer:
                    ' Please note payments quoted in the repayment plan above would have to be made at the end of each month in which they fall due.',
            },
        },
    },
};
