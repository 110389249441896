<script>
    export let success = false;
    export let urgent = false;

</script>

<style lang="scss">
    @import 'src/sass/base';

    .pill {
        display: inline-block;
        font-size: em(12);
        padding: em(5) em(10);
        font-weight: $fw-regular;
        text-transform: uppercase;
        letter-spacing: 1.25px;
        background: yellow;
        border-radius: em(5);
    }

    .success {
        background: $color-green;
        color: $color-white;
    }

    .urgent {
        background: $color-primary;
        color: $color-white;
        animation: pulse;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }

</style>

<div class="pill animate__animated" class:urgent class:success>
    <slot />
</div>
