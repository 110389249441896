<script>
    import { getContext } from 'svelte';
    import Button from 'components/Button';

    export let title;
    export let message;
    export let confirmLabel = 'OK';
    export let onClose = () => {};

    const { close } = getContext('simple-modal');

    function handleClose() {
        onClose();
        close();
    }

</script>

<style lang="scss">
    @import 'src/sass/base';

    .modal-content {
        margin-bottom: em(60);
        padding: em(45) em(45) 0 em(45);

        &__title {
            margin-bottom: em(25);
            font-size: em(20);
            font-weight: $fw-bold;
            color: $color-primary;
            line-height: 1.25;
        }

        &__desc {
            font-size: em(16);
            color: $color-gray-text;
            line-height: 1.25;
        }

        :global(p) {
            margin-bottom: em(25);
        }
    }

    .actions {
        padding: em(10) em(25);
        border-radius: 0 0 em(25) em(25);
        background: $color-gray-light;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: auto;
    }

</style>

<div class="modal">
    <div class="modal-content">
        <h2 class="modal-content__title">{title}</h2>
        <p class="modal-content__desc">
            {@html message}
        </p>
    </div>
    <div class="actions">
        <Button primary small onClick={handleClose}>{confirmLabel}</Button>
    </div>
</div>
