<script>
    import { getContext } from 'svelte';

    import Button from 'components/Button';
    import Loader from 'components/Loader';
    import Lottie from 'components/Lottie';
    import { AlertModal, alertModalOptions } from 'components/AlertModal';

    export let trigger = false;
    export let title = 'Loading ...';
    export let cancelLabel = 'Cancel';
    export let submitLabel = 'Submit';
    export let loadingLabel = 'Loading';
    export let autoSubmit = false;
    export let canSubmit = true;
    export let closeOnSubmit = true;
    export let customActions = [];
    export let onClose = () => {};
    export let onError = () => {};
    export let onCancel = () => {
        return Promise.resolve(true);
    };
    export let onSubmit = () => {
        return Promise.resolve(true);
    };

    const { open } = getContext('simple-modal');

    let isClosing = false;
    let isLoading = false;
    let isSuccessful = false;

    $: {
        if (closeOnSubmit) {
            closeOnSubmit = closeOnSubmit;
        }

        if (canSubmit && autoSubmit) {
            handleSubmit();
        }
    }

    function close(delay = 1000) {
        isClosing = true;
        trigger = false;
        onClose();

        setTimeout(() => {
            isClosing = false;
            isLoading = false;
            isSuccessful = false;
        }, delay);
    }

    function handleSubmit() {
        isLoading = true;

        onSubmit().then(
            () => {
                isSuccessful = true;
                setTimeout(close, 2000);
            },
            (error) => {
                close();
                onError();
                setTimeout(() => {
                    open(
                        AlertModal,
                        {
                            title: 'Something went wrong.',
                            message:
                                'We were unable to process your request. Please try again later or contact support for help.',
                        },
                        alertModalOptions,
                    );
                }, 1000);
            },
        );
    }

    function handleCancel() {
        onCancel().finally(() => {
            close();
        });
    }

</script>

<style lang="scss">
    @import 'src/sass/base';

    .root {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        align-items: center;
        position: fixed;
        overflow: hidden;
        z-index: 1000;
        width: 100%;
        height: 100vh;
        background: rgba($color-black, 0.66);
        backdrop-filter: blur(5px);
        animation-duration: 0.25s;

        &--closing {
            animation-delay: 0.75s;
        }
    }

    .overlay {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100vh;
        background: $color-white;
        border-radius: 0;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        animation-delay: 0.25s;
        animation-duration: 0.3s;

        &--closing {
            animation-delay: 0 !important;
        }

        @include for-tablet-landscape-up {
            width: 65%;
            height: 95vh;
            border-radius: em(25) em(25) 0 0;
        }
    }

    .header {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: em(15) em(25);
        background: $color-white;
        border-bottom: 1px solid darken($color-gray, 5%);
        border-radius: em(25) em(25) 0 0;

        &__title {
            margin-bottom: em(10);
            font-weight: $fw-bold;
            font-size: em(18);
            color: $color-primary;
        }

        @include for-tablet-portrait-up {
            flex-flow: row nowrap;
            padding: em(30) em(25);

            &__title {
                margin-bottom: 0;
                font-size: em(24);
            }
        }
    }

    .content {
        width: 100%;
        height: 100%;
        margin: em(90) 0;
        padding: em(10) em(10) em(200) em(10);
        color: $color-black;
        overflow-y: scroll;

        @include for-tablet-portrait-up {
            padding: em(10) em(25) em(200) em(25);
        }
    }

    .actions {
        display: none;
        position: relative;
        z-index: 10;
        justify-content: flex-end;
        align-items: center;
        column-gap: em(10);
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $color-secondary;
        border-top: 1px solid $color-gray;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

        &--mobile {
            display: flex;
            width: auto;
            position: relative;
            background: none;
            box-shadow: none;
            border-top: none;
            font-size: em(14);
        }

        @include for-tablet-landscape-up {
            display: flex;
            padding: em(15) em(25);

            &--mobile {
                display: none;
            }
        }
    }

    .loader {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: rgba($color-white, 0.99);

        &__success {
            width: em(150);
        }
    }

</style>

{#if trigger || isClosing}
    <div
        class="root animate__animated animate__fadeIn"
        class:animate__fadeOut={isClosing}
        class:root--closing={isClosing}>
        <div
            class="overlay animate__animated animate__slideInUp"
            class:animate__slideOutDown={isClosing}
            class:overlay--closing={isClosing}>
            <div class="header">
                <div>
                    <div class="header__title">{title}</div>
                </div>
                <div class="actions actions--mobile">
                    <Button cancel small disabled={isLoading} onClick={() => handleCancel()}>{cancelLabel}</Button>
                    {#each customActions as action}
                        <Button
                            primary
                            small
                            disabled={!action.isEnabled}
                            onClick={() => {
                                action.action();
                            }}>
                            {action.label}
                        </Button>
                    {/each}
                    {#if canSubmit}
                        <div class="animate__animated animate__bounceIn">
                            <Button primary small disabled={isLoading || !canSubmit} onClick={() => handleSubmit()}>
                                {submitLabel}
                            </Button>
                        </div>
                    {/if}
                </div>
            </div>
            <div class="content">
                {#if isLoading}
                    <div class="loader animate animate__fadeIn">
                        {#if !isSuccessful}
                            <Loader title={loadingLabel} subtitle="This might take a few seconds ..." />
                        {:else}
                            <div class="loader__success animate__animated animate__bounceIn">
                                <Lottie jsonPath="lottie/docs-uploaded.json" loop={false} />
                            </div>
                        {/if}
                    </div>
                {/if}
                <slot name="content" />
            </div>
            <div class="actions">
                <Button cancel small disabled={isLoading} onClick={() => handleCancel()}>{cancelLabel}</Button>
                {#each customActions as action}
                    <Button
                        primary
                        small
                        disabled={!action.isEnabled}
                        onClick={() => {
                            action.action();
                        }}>
                        {action.label}
                    </Button>
                {/each}
                {#if canSubmit}
                    <div class="animate__animated animate__bounceIn">
                        <Button primary small disabled={isLoading || !canSubmit} onClick={() => handleSubmit()}>
                            {isLoading ? 'Submitting ...' : submitLabel}
                        </Button>
                    </div>
                {/if}
            </div>
        </div>
    </div>
{/if}
