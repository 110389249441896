<script>
    import { createEventDispatcher, getContext } from 'svelte';
    import { Pie } from 'svelte-chartjs';

    import LOANS_CONFIG from 'configs/loans';

    import authStore from 'stores/auth';
    import loansStore from 'stores/loans';

    import { formatCurrency } from 'services/util';
    import infinityService from 'services/infinity';

    import Loader from 'components/Loader';
    import { LoadingModal, loadingModalOptions } from 'components/LoadingModal';

    export let loan;

    const dispatch = createEventDispatcher();
    const { open } = getContext('simple-modal');

    const NUM_PAYMENTS_VISIBLE_THRESHOLD = 6;
    const chartOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `$${context.formattedValue}`;
                    },
                },
            },
        },
    };

    let _loan = null;
    let payments = [];
    let isLoadingPayments = false;
    let canSeeAllLoanPayments = false;

    let docs = {
        loanAgreement: {
            id: 1,
            title: 'Loan Agreement',
            filename: 'loan_agreement',
            url: null,
        },
        salaryAssignment: {
            id: 2,
            title: 'Salary Assignment',
            filename: 'salary_assignment',
            url: null,
        },
    };

    let chart;
    let chartData = {
        labels: ['Paid', 'Outstanding'],
        datasets: [
            {
                data: [],
                backgroundColor: ['#2dc88d', '#eeeeef'],
                hoverBackgroundColor: ['#2dc88d', '#eeeeef'],
            },
        ],
    };

    $: {
        if (loan != _loan) {
            _loan = loan;
            resetDocs();
            loadPayments();
            updateChart();
        }
    }

    function resetDocs() {
        docs = {
            loanAgreement: {
                id: 1,
                title: 'Loan Agreement',
                filename: 'loan_agreement',
                url: null,
            },
            salaryAssignment: {
                id: 2,
                title: 'Salary Assignment',
                filename: 'salary_assignment',
                url: null,
            },
        };
    }

    function loadPayments() {
        canSeeAllLoanPayments = false;
        isLoadingPayments = true;

        loansStore
            .fetchPayments($authStore.customerId, loan.id)
            .then((_payments) => {
                payments = _payments;
            })
            .finally(() => {
                isLoadingPayments = false;
            });
    }

    function updateChart() {
        chartData.datasets[0].data = [loan.amountPaid, Math.max(loan.amount - loan.amountPaid, 0)];

        if (chart) {
            chart.update();
        }
    }

    function downloadLoanAgreement(doc) {
        function download(name, href) {
            const downloadLink = document.createElement('a');
            downloadLink.href = href;
            downloadLink.download = name;
            downloadLink.click();
        }

        if (doc.url) {
            download(`${doc.filename}_${_loan.id}`, doc.url);
        } else {
            let promise = infinityService.getLoanAgreementPDF({
                customerId: $authStore.customerId,
                loanId: _loan.id,
                sequenceItemKey: doc.id,
                forSigning: false,
            });

            open(
                LoadingModal,
                {
                    title: `Downloading ${doc.title}`,
                    message: 'This may take a few seconds ...',
                    promise,
                    onSuccess: (agreement) => {
                        let loanAgreement = agreement.pdf,
                            loanAgreementBase64 = 'data:application/pdf;base64,' + loanAgreement;

                        // Set the URL in memory so we don't have to download it again
                        doc.url = loanAgreementBase64;
                        download(`${doc.filename}_${loan.id}`, loanAgreementBase64);
                    },
                    onError: (error) => {
                        console.error('Error downloading file -> ', error);
                        return;
                    },
                },
                loadingModalOptions,
            );
        }
    }
</script>

<div
    class="animate__animated animate__fadeIn flex w-full flex-1 flex-col self-stretch border-l border-neutral bg-white px-6 py-4 lg:ml-2 lg:w-[60%] lg:p-6"
>
    <div>
        {#if !loan.customerSignature && (loan.status == LOANS_CONFIG.LOAN_STATUSES.ACTIVE || loan.status == LOANS_CONFIG.LOAN_STATUSES.PENDING)}
            <div
                role="alert"
                class="space-between alert mb-6 flex w-full cursor-pointer items-center border-0 bg-primary text-white shadow-md"
            >
                <div class="flex w-full items-center justify-start gap-x-6">
                    <div><i class="fa fa-2xl fa-exclamation-triangle"></i></div>
                    <div>
                        <h3 class="font-bold">Your signature is required.</h3>
                        <div class="text-sm">Your Loan Agreement is ready to be signed.</div>
                    </div>
                </div>
                <button
                    class="btn btn-sm"
                    on:keydown
                    on:click={() => {
                        dispatch('signature', { loan: loan });
                    }}>Sign Now</button
                >
            </div>
        {/if}
        <section class="mb-6 w-full">
            <div class="mb-6 space-x-1 border-b border-neutral p-2 text-lg font-light text-black">
                <span><i class="fa fa-thin fa-rectangle-list"></i></span>
                <span>Loan Summary</span>
            </div>
            <div class="space-between flex w-full items-center">
                <div class="mr-6 w-[60%]">
                    <div class="space-between flex flex-wrap items-start">
                        <div class="mb-10 w-full">
                            <div class="text-base text-gray-500">Total Loan Amount</div>
                            <div class="text-lg font-semibold text-primary lg:text-4xl">
                                {formatCurrency(loan.amount, 'TT', 'USD')}
                            </div>
                        </div>
                        <div class="mb-10 w-[50%]">
                            <div class="text-sm text-gray-500 lg:text-base">Total Paid</div>
                            <div class="font-sermiboPld text-black lg:text-xl">
                                {formatCurrency(loan.amountPaid, 'TT', 'USD')}
                            </div>
                        </div>
                        <div class="mb-10 w-[50%]">
                            <div class="whitespace-nowrap text-sm text-gray-500 lg:text-base">Settlement Balance</div>
                            <div class="font-sermibold text-black lg:text-xl">
                                {formatCurrency(loan.amountDue, 'TT', 'USD')}
                            </div>
                        </div>
                        <div class="mb-0 w-full lg:w-[45%]">
                            <div class="text-sm text-gray-500 lg:text-base">Next Payment Due</div>
                            <div class="font-sermibold text-black lg:text-xl">{loan.dueDate}</div>
                        </div>
                    </div>
                </div>
                <div class="w-[40%]">
                    <Pie bind:chart data={chartData} options={chartOptions} />
                </div>
            </div>
        </section>
        <section class="mb-6 w-full">
            <div class="mb-6 space-x-1 border-b border-neutral p-2 text-lg font-light text-black">
                <span><i class="fa fa-thin fa-money"></i></span>
                <span>Payments</span>
            </div>
            <div class="overflow-x-auto">
                {#if isLoadingPayments}
                    <div class="flex items-center justify-center gap-x-2">
                        <span class="loading loading-spinner text-primary"></span>
                        <span>Loading Payments</span>
                    </div>
                {:else if payments.length == 0}
                    <Loader
                        animationPath="lottie/alert-list.json"
                        animationLoop={false}
                        title="No payments have been made."
                        subtitle="Check back later after you've made your first payment."
                    />
                {:else}
                    <table class="table table-zebra table-md">
                        <!-- head -->
                        <thead>
                            <tr class="border-b-neutral">
                                <th></th>
                                <th>Payment Date</th>
                                <th>Payment Amount</th>
                                <th>Payment Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {#each payments as payment, i}
                                <tr class="border-b-neutral">
                                    <th>{i + 1}</th>
                                    <td>{payment.date}</td>
                                    <td>{formatCurrency(payment.amount, 'TT', 'USD')}</td>
                                    <td
                                        ><div class="badge badge-primary uppercase text-white">
                                            {payment.type}
                                        </div>
                                    </td>
                                </tr>
                            {/each}
                        </tbody>
                    </table>
                {/if}
            </div>
        </section>
        {#if loan.customerSignature}
            <section class="mb-6 w-full">
                <div class="mb-6 space-x-1 border-b border-neutral p-2 text-lg font-light text-black">
                    <span><i class="fa fa-thin fa-file"></i></span>
                    <span>Documents</span>
                </div>
                <div class="space-y-4">
                    {#each Object.keys(docs) as key}
                        <button
                            class="btn btn-secondary flex items-center justify-start gap-x-2"
                            on:keydown
                            on:click={() => {
                                downloadLoanAgreement(docs[key]);
                            }}
                        >
                            <i class="fa fa-download fa-lg text-primary"></i>
                            <div>Download {docs[key].title}</div>
                        </button>
                    {/each}
                </div>
            </section>
        {/if}
    </div>
</div>

<style lang="scss">
    @use 'src/sass/base' as *;
    @use 'src/sass/tailwind';
</style>
