<script>
    import { createEventDispatcher, getContext } from 'svelte';
    import { Pie } from 'svelte-chartjs';

    import LOANS_CONFIG from 'configs/loans';

    import authStore from 'stores/auth';
    import loansStore from 'stores/loans';

    import { formatCurrency } from 'services/util';
    import infinityService from 'services/infinity';

    import Lottie from 'components/Lottie';
    import Loader from 'components/Loader';
    import { LoadingModal, loadingModalOptions } from 'components/LoadingModal';

    export let loan;

    const dispatch = createEventDispatcher();
    const { open } = getContext('simple-modal');

    const NUM_PAYMENTS_VISIBLE_THRESHOLD = 6;
    const chartOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `$${context.formattedValue}`;
                    },
                },
            },
        },
    };

    let _loan = null;
    let payments = [];
    let isLoadingPayments = false;
    let canSeeAllLoanPayments = false;

    let docs = {
        loanAgreement: {
            id: 1,
            title: 'Loan Agreement',
            filename: 'loan_agreement',
            url: null,
        },
        salaryAssignment: {
            id: 2,
            title: 'Salary Assignment',
            filename: 'salary_assignment',
            url: null,
        },
    };

    let chart;
    let chartData = {
        labels: ['Paid', 'Outstanding'],
        datasets: [
            {
                data: [],
                backgroundColor: ['#2dc88d', '#eeeeef'],
                hoverBackgroundColor: ['#2dc88d', '#eeeeef'],
            },
        ],
    };

    $: {
        if (loan != _loan) {
            _loan = loan;
            loadPayments();
            updateChart();
        }
    }

    function loadPayments() {
        canSeeAllLoanPayments = false;
        isLoadingPayments = true;

        loansStore
            .fetchPayments($authStore.customerId, loan.id)
            .then((_payments) => {
                payments = _payments;
            })
            .finally(() => {
                isLoadingPayments = false;
            });
    }

    function updateChart() {
        chartData.datasets[0].data = [loan.amountPaid, Math.max(loan.amount - loan.amountPaid, 0)];

        if (chart) {
            chart.update();
        }
    }

    function downloadLoanAgreement(doc) {
        function download(name, href) {
            const downloadLink = document.createElement('a');
            downloadLink.href = href;
            downloadLink.download = name;
            downloadLink.click();
        }

        if (doc.url) {
            download(`${doc.filename}_${loan.id}`, doc.url);
        } else {
            let promise = infinityService.getLoanAgreementPDF({
                customerId: $authStore.customerId,
                loanId: loan.id,
                sequenceItemKey: doc.id,
                forSigning: false,
            });

            open(
                LoadingModal,
                {
                    title: `Downloading ${doc.title}`,
                    message: 'This may take a few seconds ...',
                    promise,
                    onSuccess: (agreement) => {
                        let loanAgreement = agreement.pdf,
                            loanAgreementBase64 = 'data:application/pdf;base64,' + loanAgreement;

                        // Set the URL in memory so we don't have to download it again
                        doc.url = loanAgreementBase64;
                        download(`${doc.filename}_${loan.id}`, loanAgreementBase64);
                    },
                    onError: (error) => {
                        console.error('Error downloading file -> ', error);
                        return;
                    },
                },
                loadingModalOptions,
            );
        }
    }

</script>

<style lang="scss">
    @import 'src/sass/base';

    .loan-details {
        display: flex;
        flex-flow: column nowrap;
        flex: 1;
        width: 100%;
        padding: em(10) em(10) em(20) em(10);
        background: $color-white;
        border-left: 1px solid rgba($color-black, 0.08);
        align-self: stretch;
        animation-delay: 0.3s;
        animation-duration: 0.25s;

        @include for-tablet-landscape-up {
            width: 60%;
            margin-left: em(5);
            padding: em(15) em(25) em(30) em(25);
        }
    }

    .loan-details-section {
        width: 100%;
        margin: 0 0 em(40) 0;

        &__title {
            margin-bottom: em(25);
            padding: em(8) 0;
            font-size: em(14);
            font-weight: $fw-medium;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            color: $color-primary;
            border-bottom: 1px solid $color-gray;
        }
    }

    .loan-details-summary-section {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &__details {
            width: 60%;
            margin-right: em(25);
        }

        &__graph {
            width: 40%;
        }
    }

    .loan-details-summary {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: row wrap;

        &__item {
            margin: 0 0 em(50) 0;
            width: 45%;

            &--no-margin {
                margin-bottom: 0;
            }

            &--stretch {
                width: 100%;
            }
        }

        &__label {
            margin-bottom: em(7);
            font-size: em(14);
            font-weight: $fw-regular;
            color: $color-gray-text;
            font-family: $ff-primary;
        }

        &__value {
            color: $color-black;
            font-size: em(16);
            font-weight: $fw-regular;
            font-family: $ff-primary;

            &--big {
                font-family: $ff-headers;
                color: $color-primary;
                font-weight: $fw-medium;
                font-size: em(24);
            }
        }

        @include for-tablet-landscape-up {
            &__label {
                font-size: em(16);
            }

            &__value {
                font-size: em(18);
                font-weight: $fw-medium;

                &--big {
                    font-size: em(36);
                }
            }
        }
    }

    .loan-details-signature {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: em(8) em(5);
        margin: em(15) 0;
        font-weight: $fw-regular;
        line-height: 1.3;
        border: 1px solid darken($color-secondary, 5%);
        background: $color-secondary;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.25s;

        &__icon {
            margin-right: em(25);
            width: em(75);
        }

        &__header {
            margin-bottom: em(5);
            font-size: em(16);
            font-weight: $fw-medium;
            color: $color-primary;
        }

        &__desc {
            font-size: em(14);
            font-weight: $fw-regular;
            color: $color-black;
        }

        &:hover {
            animation: pulse;
            animation-duration: 0.25s;
            background: $color-secondary;
        }
    }

    .loan-payments {
        overflow: hidden;

        &__loader-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        &__loader {
            position: relative;
            top: -50px;
            width: 300px;
            height: 300px;
        }
    }

    .loan-payment {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: em(15) em(10);
        background: $color-white;
        border-bottom: 1px solid $color-gray;
        transition: 0.2s all ease-in;
        font-family: $ff-primary;

        &:hover {
            background: $color-secondary;
        }

        div {
            flex-basis: 33.3%;
        }

        &--hidden {
            display: none !important;
        }

        &--show-more {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-basis: auto;
            box-shadow: 0 2px 5px rgba($color-primary, 0.25);
            border-color: $color-primary;
            font-size: em(14);
            font-weight: $fw-bold;
            color: $color-primary;
            text-transform: uppercase;
            letter-spacing: 1.2px;
            text-align: center;
            cursor: pointer;

            &:hover {
                background: $color-secondary;
                box-shadow: 0 2px 5px rgba($color-primary, 0.25);
            }
        }

        &__num-wrapper {
            display: none;
        }

        &__num {
            display: inline-block;
            padding: em(4) em(8);
            border-radius: 50%;
            background: $color-white;
            border: 1px solid $color-primary;
            font-size: em(14);
            font-weight: $fw-medium;
            color: $color-primary;
        }

        &__type {
            display: inline-block;
            padding: em(5) em(15);
            text-transform: uppercase;
            letter-spacing: 1.25px;
            background: $color-secondary;
            border-radius: em(5);
            color: $color-primary;
            font-size: em(12);
            font-weight: $fw-medium;
        }

        &__amount {
            font-size: em(14);
            font-weight: $fw-bold;
            color: $color-primary;
        }

        @include for-tablet-landscape-up {
            justify-content: space-between;

            &__num-wrapper {
                display: block;
            }

            &__type {
                font-size: em(12);
            }

            &__amount {
                font-size: em(16);
            }
        }
    }

    .loan-docs {
        &__doc {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 5px;
            cursor: pointer;
            margin-bottom: em(5);
            font-weight: $fw-medium;
            color: $color-primary;

            &:hover {
                color: lighten($color-primary, 25%);
            }
        }
    }

</style>

<div class="loan-details animate__animated animate__fadeIn">
    <div class="loan-details-wrapper">
        {#if !loan.customerSignature && (loan.status == LOANS_CONFIG.LOAN_STATUSES.ACTIVE || loan.status == LOANS_CONFIG.LOAN_STATUSES.PENDING)}
            <div
                class="loan-details-signature animate__animated animate__fadeIn"
                on:click={() => {
                    dispatch('signature', { loan: loan });
                }}>
                <div class="loan-details-signature__icon">
                    <Lottie jsonPath="lottie/docs-alert.json" />
                </div>
                <div>
                    <div class="loan-details-signature__header">Your signature is required.</div>
                    <div class="loan-details-signature__desc">
                        Your Loan Agreement is ready to be signed. Click here to sign now.
                    </div>
                </div>
            </div>
        {/if}
        <section class="loan-details-section">
            <div class="loan-details-section__title">Loan Summary</div>
            <div class="loan-details-summary-section">
                <div class="loan-details-summary-section__details">
                    <div class="loan-details-summary">
                        <div class="loan-details-summary__item loan-details-summary__item--stretch">
                            <div class="loan-details-summary__label">Total Loan Amount</div>
                            <div class="loan-details-summary__value loan-details-summary__value--big">
                                {formatCurrency(loan.amount, 'TT', 'USD')}
                            </div>
                        </div>
                        <div class="loan-details-summary__item">
                            <div class="loan-details-summary__label">Total Paid</div>
                            <div class="loan-details-summary__value">
                                {formatCurrency(loan.amountPaid, 'TT', 'USD')}
                            </div>
                        </div>
                        <div class="loan-details-summary__item">
                            <div class="loan-details-summary__label">Settlement Balance</div>
                            <div class="loan-details-summary__value">{formatCurrency(loan.amountDue, 'TT', 'USD')}</div>
                        </div>
                        <div class="loan-details-summary__item loan-details-summary__item--no-margin">
                            <div class="loan-details-summary__label">Next Payment Due</div>
                            <div class="loan-details-summary__value">{loan.dueDate}</div>
                        </div>
                    </div>
                </div>
                <div class="loan-details-summary-section__graph">
                    <Pie bind:chart data={chartData} options={chartOptions} />
                </div>
            </div>
        </section>
        <section class="loan-details-section">
            <div class="loan-details-section__title">My Payments</div>
            <div class="loan-payments">
                {#if isLoadingPayments}
                    <div class="loan-payments__loader-container">
                        <div class="loan-payments__loader">
                            <Lottie jsonPath="lottie/loading-list-4.json" />
                        </div>
                    </div>
                {:else if payments.length == 0}
                    <Loader
                        animationPath="lottie/alert-list.json"
                        animationLoop={false}
                        title="No payments have been made."
                        subtitle="Check back later after you've made your first payment." />
                {:else}
                    {#each payments as payment, i}
                        <div
                            class="loan-payment animate__animated animate__fadeInDown"
                            class:loan-payment--hidden={i >= NUM_PAYMENTS_VISIBLE_THRESHOLD && !canSeeAllLoanPayments}
                            style={`animation-delay: ${i < NUM_PAYMENTS_VISIBLE_THRESHOLD ? i * 150 : (i - NUM_PAYMENTS_VISIBLE_THRESHOLD) * 150}ms`}>
                            <div class="loan-payment__num-wrapper">
                                <div class="loan-payment__num">{i + 1}</div>
                            </div>
                            <div class="loan-payment__date">{payment.date}</div>
                            <div class="loan-payment__amount">{formatCurrency(payment.amount, 'TT', 'USD')}</div>
                            <div>
                                <div class="loan-payment__type">{payment.type}</div>
                            </div>
                        </div>
                    {/each}
                    {#if payments.length > NUM_PAYMENTS_VISIBLE_THRESHOLD && !canSeeAllLoanPayments}
                        <div
                            class="loan-payment loan-payment--show-more animate__animated animate__fadeInDown"
                            style={`animation-delay: ${NUM_PAYMENTS_VISIBLE_THRESHOLD * 150}ms`}
                            on:click={() => (canSeeAllLoanPayments = true)}>
                            <span> Load all Payments </span>
                            <img src="/images/icon_caret_down.png" height="15" alt="caret-down" />
                        </div>
                    {/if}
                {/if}
            </div>
        </section>
        {#if loan.customerSignature}
            <section class="loan-details-section">
                <div class="loan-details-section__title">Documents</div>
                <div class="loan-docs">
                    {#each Object.keys(docs) as key}
                        <div
                            class="loan-docs__doc"
                            on:click={() => {
                                downloadLoanAgreement(docs[key]);
                            }}>
                            <img src="/images/pdf_icon.png" height="30" alt="pdf " />
                            <div>Download {docs[key].title}</div>
                        </div>
                    {/each}
                </div>
            </section>
        {/if}
    </div>
</div>
