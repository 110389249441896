<script>
    import { getContext } from 'svelte';

    import infinityService from 'services/infinity';

    import Button from 'components/Button';
    import Loader from 'components/Loader';

    export let onClose = () => {};

    export let token;

    let isLoading = false,
        isPasswordRevealed = false,
        isPasswordEmailSent = token != null,
        isPasswordReset = false;

    let emailAddress, password, confirmPassword, error;

    const { close } = getContext('simple-modal');

    async function handleForgotPassword() {
        const RESPONSE_SENT = 'Sent',
            RESPONSE_UNIQUE = 'EmailNotUnique',
            RESPONSE_FAILED = 'EmailNotFound';

        isLoading = true;
        error = null;

        try {
            let { status } = await infinityService.forgotPassword({ emailAddress });

            if (status == RESPONSE_SENT) {
                isPasswordEmailSent = true;
            } else if (status == RESPONSE_UNIQUE) {
                error =
                    'Multiple users in our system are registered with your email address. Please contact support for help.';
            } else {
                error =
                    'We are unable to find registered user with that email address in our system. Please contact support for help or try again.';
            }

            isLoading = false;
        } catch (e) {
            console.error(e);

            error = 'Something went wrong. Please try again.';
            isLoading = false;
        }
    }

    async function handleResetPassword() {
        error = null;

        if (!password || !confirmPassword) {
            error = 'Please input all fields before continuing.';
            return;
        }

        if (password != confirmPassword) {
            error = "Please make sure you've re-typed your password correctly.";
            return;
        }

        isLoading = true;

        try {
            let { status } = await infinityService.resetPassword({ token, password });

            if (status) {
                isPasswordReset = true;
                isLoading = false;
                password = null;
                confirmPassword = null;
            }
        } catch (e) {
            console.error(e);

            isLoading = false;
            error = 'Something went wrong. Please try again.';
        }
    }

    function handleClose() {
        onClose();
        close();
    }

</script>

<style lang="scss">
    @import 'src/sass/base';

    .modal-content {
        margin-bottom: em(30);
        padding: em(30) em(30) 0 em(30);

        &__title {
            margin-bottom: em(5);
            font-size: em(28);
            font-weight: $fw-bold;
            color: $color-primary;
            line-height: 1.25;
            text-align: center;
        }

        &__subtitle {
            margin-bottom: em(30);
            font-size: em(20);
            color: $color-black;
            text-align: center;
            line-height: 1.1;
        }

        :global(p) {
            margin-bottom: em(25);
        }
    }

    .errors {
        margin-bottom: em(10);
        color: $color-red;
        text-align: center;
    }

    .form {
        &__control {
            margin: em(20) 0;
        }

        &__input {
            padding: em(10);
        }
    }

    .split-input {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        input {
            margin-right: em(15);
            padding: em(10);
        }
    }

    .actions {
        padding: em(10) em(25);
        border-radius: 0 0 em(25) em(25);
        background: $color-gray-light;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: em(5);
        width: auto;
    }

</style>

<div class="modal">
    <div class="modal-content">
        <h2 class="modal-content__title">Forgot Password</h2>
        <div class="modal-content__subtitle">
            {#if token}
                You're almost done! Please input your new password to continue.
            {:else}Forgot your password? No problem. Please input your email address to continue.{/if}
        </div>
        <div class="modal__desc">
            {#if error}
                <p class="errors animate__animated animate__fadeIn">{error}</p>
            {/if}

            {#if (!isPasswordEmailSent || token) && !isPasswordReset}
                <form class="form" name="forgotPasswordForm">
                    {#if token}
                        <div class="form__control">
                            <label for="password">Password</label>
                            <div class="split-input">
                                {#if isPasswordRevealed}
                                    <input name="password" type="text" bind:value={password} />
                                {:else}<input name="password" type="password" bind:value={password} />{/if}
                                <img
                                    class="reveal-icon"
                                    alt="reveal"
                                    on:click={() => (isPasswordRevealed = !isPasswordRevealed)}
                                    src={`/images/icon_reveal_${isPasswordRevealed ? 'hide' : 'show'}.png`}
                                    height="30" />
                            </div>
                        </div>
                        <div class="form__control">
                            <label for="confirmPassword">Repeat Password</label>
                            <div class="split-input">
                                {#if isPasswordRevealed}
                                    <input name="repeatPassword" type="text" bind:value={confirmPassword} />
                                {:else}<input name="repeatPassword" type="password" bind:value={confirmPassword} />{/if}
                                <img
                                    class="reveal-icon"
                                    alt="reveal"
                                    on:click={() => (isPasswordRevealed = !isPasswordRevealed)}
                                    src={`/images/icon_reveal_${isPasswordRevealed ? 'hide' : 'show'}.png`}
                                    height="30" />
                            </div>
                        </div>
                    {:else}
                        <div class="form__control">
                            <label for="emailAddress" class="form__label">Email Address</label>
                            <input class="form__input" type="email" name="emailAddress" bind:value={emailAddress} />
                        </div>
                    {/if}
                </form>
            {:else if isPasswordEmailSent && !token}
                <Loader
                    animationPath="lottie/register-checkmark.json"
                    animationLoop={false}
                    title="Thanks!"
                    subtitle={`An email was sent to ${emailAddress} with instructions on how to reset your password.`} />
            {:else if isPasswordReset}
                <Loader
                    animationPath="lottie/register-checkmark.json"
                    animationLoop={false}
                    title="Your password is reset!"
                    subtitle="Your password has been reset successfully. You can now use your new password to login." />
            {/if}
        </div>
    </div>
    <div class="actions">
        <Button secondary fullWidth small onClick={handleClose} disabled={isLoading}>Close</Button>
        {#if token && !isPasswordReset}
            <Button primary fullWidth small onClick={handleResetPassword} disabled={!token || isLoading}>
                {isLoading ? 'Resetting Password ...' : 'Reset Password'}
            </Button>
        {:else}
            <Button
                primary
                fullWidth
                small
                onClick={handleForgotPassword}
                disabled={!emailAddress || isLoading || isPasswordEmailSent}>
                {isLoading ? 'Submitting ...' : 'Submit'}
            </Button>
        {/if}
    </div>
</div>
