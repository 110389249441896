import { getFunctions, httpsCallable } from 'firebase/functions';

import SHARED_CONFIG from 'configs/shared';
import REGISTER_CONFIG from 'configs/register';

import analyticsService from 'services/analytics';
import { formatCurrency } from 'services/util';

export function login(data) {
    const functions = getFunctions();
    const login = httpsCallable(functions, 'login');
    console.debug('Login -> ', data.username);

    return login(data).then(
        (response) => {
            return response.data;
        },
        (error) => {
            console.error('Login Error -> ', error);
            throw new Error(error);
        },
    );
}

export function createCustomer(data) {
    const functions = getFunctions();
    const createCustomer = httpsCallable(functions, 'createCustomer');
    const createLoan = httpsCallable(functions, 'createLoan');
    console.debug('Create Customer -> ', data);

    return createCustomer(data).then(
        (response) => {
            if (response.data.isSuccessful) {
                let createLoanRequest = {
                    countryKey: data.essentials.country.value,
                    loanType: data.essentials.loanType,
                    customerId: response.data.customerId,
                    loanAmount: data.loan.amount,
                };

                // Add extra fields to Loan Request for Car loans
                if (data.essentials.loanType == SHARED_CONFIG.LOAN_TYPES.CAR) {
                    let description;
                    let carAmount = formatCurrency(parseFloat(data.loan.car.amount), 'tt', 'TTD');
                    let downPaymentAmount = formatCurrency(
                        data.loan.car.downPayment ? parseFloat(data.loan.car.downPayment) : 0,
                        'tt',
                        'TTD',
                    );

                    if (data.loan.car.loanType.value == SHARED_CONFIG.CAR_LOAN_TYPES.COLLATERAL) {
                        description = `Customer is using an existing vehicle as collateral. Estimated cost of the vehicle is ${carAmount}.`;
                    } else {
                        description = `Customer is interested in purchasing a new or used vehicle. Estimated cost of the vehicle is ${carAmount}. Total down-payment on the vehicle is ${downPaymentAmount}.`;
                    }

                    createLoanRequest['collateral'] = {
                        description,
                        year: data.car.vehicle.year,
                        make: data.car.vehicle.make,
                        model: data.car.vehicle.model,
                        licensePlateNumber: data.car.licensePlateNumber,
                    };

                    if (data.car.insurance.isValid && data.car.insurance.isValid.value) {
                        createLoanRequest['insurance'] = {
                            company: `${data.car.insurance.type?.label} - ${data.car.insurance.company}`,
                        };
                    }
                }

                console.debug('Created Customer Successfully. Creating Loan and updating Custom Fields ...');

                // Track Event
                analyticsService.track(analyticsService.CUSTOMER_CREATED, {
                    firstName: data.details.firstName,
                    lastName: data.details.lastName,
                    customerId: response.data.customerId,
                });

                let promises = [
                    createLoan(createLoanRequest),
                    updateCustomField({
                        customerId: response.data.customerId,
                        fieldId: REGISTER_CONFIG.CUSTOM_FIELDS.LOAN_DURATION,
                        fieldValue: data.loan.numPayments.value,
                    }),
                    updateCustomField({
                        customerId: response.data.customerId,
                        fieldId: REGISTER_CONFIG.CUSTOM_FIELDS.PEP,
                        fieldValue: data.details.pep.value ? 'Yes' : 'No',
                    }),
                    updateCustomField({
                        customerId: response.data.customerId,
                        fieldId: REGISTER_CONFIG.CUSTOM_FIELDS.USE_OF_FUNDS,
                        fieldValue: data.loan.reason?.value ?? 'Personal',
                    }),
                ];

                if (data.essentials.loanType == SHARED_CONFIG.LOAN_TYPES.BUSINESS) {
                    promises.push(
                        updateCustomField({
                            customerId: response.data.customerId,
                            fieldId: REGISTER_CONFIG.CUSTOM_FIELDS.OPERATIONS_START_DATE,
                            fieldValue: `${data.business.startDate.date}`,
                        }),
                        updateCustomField({
                            customerId: response.data.customerId,
                            fieldId: REGISTER_CONFIG.CUSTOM_FIELDS.AVERAGE_MONTHLY_SALES_REVENUE,
                            fieldValue: data.business.averageMonthlySalesRevenue,
                        }),
                        updateCustomField({
                            customerId: response.data.customerId,
                            fieldId: REGISTER_CONFIG.CUSTOM_FIELDS.OUTSTANDING_LOANS,
                            fieldValue:
                                data.business.outstandingLoans == true
                                    ? 'Yes - ' + data.business.outstandingLoansDescription
                                    : 'No',
                        }),
                        updateCustomField({
                            customerId: response.data.customerId,
                            fieldId: REGISTER_CONFIG.CUSTOM_FIELDS.USE_OF_FUNDS,
                            fieldValue: data.business.reasonForBorrowing,
                        }),
                        updateCustomField({
                            customerId: response.data.customerId,
                            fieldId: REGISTER_CONFIG.CUSTOM_FIELDS.FULL_TIME_EMPLOYEES,
                            fieldValue: data.business.numEmployees.fullTime,
                        }),
                        updateCustomField({
                            customerId: response.data.customerId,
                            fieldId: REGISTER_CONFIG.CUSTOM_FIELDS.PART_TIME_EMPLOYEES,
                            fieldValue: data.business.numEmployees.partTime,
                        }),
                        updateCustomField({
                            customerId: response.data.customerId,
                            fieldId: REGISTER_CONFIG.CUSTOM_FIELDS.BUSINESS_TERMS_AND_CONDITIONS,
                            fieldValue: 'Signed',
                        }),
                    );
                }

                if (data.essentials.loanType == SHARED_CONFIG.LOAN_TYPES.CAR) {
                    updateCustomField({
                        customerId: response.data.customerId,
                        fieldId: REGISTER_CONFIG.CUSTOM_FIELDS.BUSINESS_TERMS_AND_CONDITIONS,
                        fieldValue: 'Signed',
                    });
                }

                return Promise.all(promises).then((results) => {
                    console.debug('Create Customer Response -> ', results);

                    let loanResponse = results[0];

                    if (loanResponse.data.isSuccessful) {
                        analyticsService.track(analyticsService.EVENTS.APPLICATION_COMPLETED, {
                            firstName: data.details.firstName,
                            lastName: data.details.lastName,
                            loanType: data.essentials.loanType,
                            customerId: response.data.customerId,
                            loanId: loanResponse.data.loanId,
                        });
                    } else {
                        analyticsService.track(analyticsService.EVENTS.CUSTOMER_WITHOUT_LOAN, {
                            firstName: data.details.firstName,
                            lastName: data.details.lastName,
                            loanType: data.essentials.loanType,
                            customerId: response.data.customerId,
                        });
                    }

                    return results;
                });
            } else {
                console.error('Create Customer Error -> ', response.data);
                throw new Error('Failed to create customer ...');
            }
        },
        (error) => {
            console.error('Create Customer Error -> ', error);
            throw new Error('Failed to create customer ...');
        },
    );
}

export function createLoan(data) {
    const functions = getFunctions();
    const createLoan = httpsCallable(functions, 'createLoan');
    console.debug('Create Loan -> ', data);

    return createLoan(data).then(
        (response) => {
            console.debug('Create Loan Success-> ', response.data);
            return response.data;
        },
        (error) => {
            console.error('Create Loan Error -> ', error.message);
            throw new Error(error.message);
        },
    );
}

export function updateCustomField(data) {
    const functions = getFunctions();
    const updateCustomField = httpsCallable(functions, 'updateCustomField');
    console.debug('Update Custom Field -> ', data);

    return updateCustomField(data).then(
        (response) => {
            console.debug('Update Customer Field Success -> ', response.data);
            return response.data;
        },
        (error) => {
            console.error('Update Custom Field Error -> ', error);
            throw new Error(error);
        },
    );
}

export function checkCustomerExists(data) {
    const functions = getFunctions();
    const checkCustomerExists = httpsCallable(functions, 'checkCustomerExists');
    console.debug('Check Customer Exists -> ', data);

    return checkCustomerExists(data).then(
        (response) => {
            return response.data;
        },
        (error) => {
            console.error('Check Customer Exists Error -> ', error);
            throw new Error(error);
        },
    );
}

export function getCustomer(data) {
    const functions = getFunctions();
    const getCustomer = httpsCallable(functions, 'getCustomer');
    console.debug('Get Customer ->');

    return getCustomer(data).then(
        (response) => {
            return response.data;
        },
        (error) => {
            console.error('Get CustomerError -> ', error);
            throw new Error(error);
        },
    );
}

export function getCustomerLoans(data) {
    const functions = getFunctions();
    const getCustomerLoans = httpsCallable(functions, 'getCustomerLoans');
    console.debug('Get Customer Loans ->');

    return getCustomerLoans(data).then(
        (response) => {
            return response.data;
        },
        (error) => {
            console.error('Get Customer Loans Error -> ', error);
            throw new Error(error);
        },
    );
}

export function getLoanPayments(data) {
    const functions = getFunctions();
    const getLoanPayments = httpsCallable(functions, 'getLoanPayments');
    console.debug('Get Loan Payments ->');

    return getLoanPayments(data).then(
        (response) => {
            return response.data;
        },
        (error) => {
            console.error('Get Loan Payments Error -> ', error);
            throw new Error(error);
        },
    );
}

export function getCustomerFiles(data) {
    const functions = getFunctions();
    const getCustomerFiles = httpsCallable(functions, 'getCustomerFiles');
    console.debug('Get Customer Files ->');

    return getCustomerFiles(data).then(
        (response) => {
            return response.data;
        },
        (error) => {
            console.error('Get Customer Files Error -> ', error);
            throw new Error(error);
        },
    );
}

export function getCustomerFile(data) {
    const functions = getFunctions();
    const getCustomerFile = httpsCallable(functions, 'getCustomerFile');
    console.debug('Get Customer File -> ', data.fileId);

    return getCustomerFile(data).then(
        (response) => {
            return response.data;
        },
        (error) => {
            console.error('Get Customer File Error -> ', error);
            throw new Error(error);
        },
    );
}

export function createCustomerFile(data) {
    const functions = getFunctions();
    const createCustomerFile = httpsCallable(functions, 'createCustomerFile');
    console.debug('Create Customer File ->');

    return createCustomerFile(data).then(
        (response) => {
            return response.data;
        },
        (error) => {
            console.error('Create Customer File Error -> ', error);
            throw new Error(error);
        },
    );
}

export function getLoanAgreement(data) {
    const functions = getFunctions();
    const getLoanAgreement = httpsCallable(functions, 'getLoanAgreement');
    console.debug('GetLoanAgreement -> ');

    return getLoanAgreement(data).then(
        (response) => {
            return response.data;
        },
        (error) => {
            console.error('Get Loan Agreement Error -> ', error);
            throw new Error(error);
        },
    );
}

export function getLoanAgreementPDF(data) {
    const functions = getFunctions();
    const getLoanAgreementPDF = httpsCallable(functions, 'getLoanAgreementPDF');
    console.debug('GetLoanAgreement PDF -> ');

    return getLoanAgreementPDF(data).then(
        (response) => {
            return response.data;
        },
        (error) => {
            console.error('Get Loan Agreement PDF Error -> ', error);
            throw new Error(error);
        },
    );
}

export function getLoanAgreementSequence(data) {
    const functions = getFunctions();
    const getLoanAgreementSequence = httpsCallable(functions, 'getLoanAgreementSequence');
    console.debug('GetLoanAgreementSequence -> ');

    return getLoanAgreementSequence(data).then(
        (response) => {
            return response.data;
        },
        (error) => {
            console.error('Get Loan Agreement Sequence Error -> ', error);
            throw new Error(error);
        },
    );
}

export function setLoanSigned(data) {
    const functions = getFunctions();
    const setLoanSigned = httpsCallable(functions, 'setLoanSigned');
    console.debug('SetLoanSigned -> ');

    return setLoanSigned(data).then(
        (response) => {
            console.debug('Set Loan Signed Success -> ', response.data);
            return response.data;
        },
        (error) => {
            console.error('Set Loan Signed Error -> ', error);
            throw new Error(error);
        },
    );
}

export function forgotPassword(data) {
    const functions = getFunctions();
    const forgotPassword = httpsCallable(functions, 'forgotPassword');
    console.debug('ForgotPassword -> ');

    return forgotPassword(data).then(
        (response) => {
            console.debug('Forgot Password Success -> ', response.data);
            return response.data;
        },
        (error) => {
            console.error('Forgot Password Error -> ', error);
            throw new Error(error);
        },
    );
}

export function resetPassword(data) {
    const functions = getFunctions();
    const resetPassword = httpsCallable(functions, 'resetPassword');
    console.debug('ResetPassword -> ');

    return resetPassword(data).then(
        (response) => {
            console.debug('Reset Password Success -> ', response.data);
            return response.data;
        },
        (error) => {
            console.error('Reset Password Error -> ', error);
            throw new Error(error);
        },
    );
}

export default {
    login,
    createCustomer,
    createLoan,
    updateCustomField,
    checkCustomerExists,
    getCustomerLoans,
    getCustomer,
    getLoanPayments,
    getCustomerFiles,
    getCustomerFile,
    createCustomerFile,
    getLoanAgreementPDF,
    getLoanAgreement,
    getLoanAgreementSequence,
    setLoanSigned,
    forgotPassword,
    resetPassword,
};
